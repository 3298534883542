<template>
    <v-container fluid class="mx-auto">
        <v-row class="mt-10">
            <v-col cols="12" lg="3" md="3">
                <v-combobox :items="merchants" v-model="selected" clearable rounded solo dense></v-combobox>
            </v-col>
            <v-col cols="12" lg="3" md="3">
                <v-btn block rounded @click="load" :loading="loading">
                    <v-icon>mdi-magnify</v-icon>&nbsp;
                    load balance
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-card-text>
                        <v-data-table :headers="headers" :items="balance"></v-data-table>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { mapActions, mapState } from "vuex";

import services from "../services/services";

export default {
    name: "Balance",
    data: () => ({
        dialog: false,
        merchants: [],
        selected: null,
        loading: false,
        headers: [
            { text: "Vodacom USD", value: "vodacom_usd", align: "center" },
            { text: "Vodacom CDF", value: "vodacom_cdf", align: "center" },
            { text: "Airtel USD", value: "airtel_usd", align: "center" },
            { text: "Airtel CDF", value: "airtel_cdf", align: "center" },
            { text: "Orange USD", value: "orange_usd", align: "center" },
            { text: "Orange CDF", value: "orange_cdf", align: "center" }
        ],
        balance: []
    }),
    methods: {
        ...mapActions(["setInstutionMerchants"]),
        async load() {
            this.loading = true
            this.balance = []
            for (let i = 0; i < this.institutionMerchants.length; i++) {
                if (this.institutionMerchants[i][0] === this.selected) {
                    const data = {
                        "merchantCode": this.institutionMerchants[i][1]
                    }
                    const response = await services.get_merchant_balance(data)

                    // formattage de l'affichage de la balance
                    let tempBalance = response.balance
                    tempBalance.vodacom_usd = new Intl.NumberFormat('fr-FR').format(response.balance.vodacom_usd)
                    tempBalance.vodacom_cdf = new Intl.NumberFormat('fr-FR').format(response.balance.vodacom_cdf)
                    tempBalance.airtel_usd = new Intl.NumberFormat('fr-FR').format(response.balance.airtel_usd)
                    tempBalance.airtel_cdf = new Intl.NumberFormat('fr-FR').format(response.balance.airtel_cdf)
                    tempBalance.orange_usd = new Intl.NumberFormat('fr-FR').format(response.balance.orange_usd)
                    tempBalance.orange_cdf = new Intl.NumberFormat('fr-FR').format(response.balance.orange_cdf)

                    this.balance.push(tempBalance)
                    this.loading = false
                    console.log(this.balance)
                    return;
                }
            }
            this.loading = false
        }
    },
    computed: {
        ...mapState(['institutionMerchants'])
    },
    async mounted() {
        await this.setInstutionMerchants()

        this.$nextTick(() => {
            for (let i = 0; i < this.institutionMerchants.length; i++) {
                this.merchants.push(this.institutionMerchants[i][0]);
            }
        })
    }
}
</script>


<style scoped>
</style>